<template>
	<ion-modal
		v-if="!loading"
		canDismiss="false"
		class="reservation-confirmation-modal"
		ref="modal"
		:is-open="cancelModalOpen"
	>
		<div class="reservation-confirmation-modal__container">
			<p class="reservation-confirmation-modal__header">
				Cancel Reservation
			</p>
			<div v-if="cancelationMessage" class="reservation-confirmation-modal__late-cancel-message">
				<ion-icon color="warning" :ios="alertCircle" :md="alertCircle" slot="start"></ion-icon>
				<p>
					{{ cancelationMessage }}
				</p>
			</div>
			<div
				v-for="(session, index) in orderReservations"
				:key="session.sessionStart"
				:class="['reservation-booking-group', index === 0 ? 'reservation-booking-group__first-session' : '', index === orderReservations.length-1 ? 'reservation-booking-group__last-session' : '']"
			>
				<div class="reservation-booking-group__text">
					<p>
						<b>{{ formatTime(session.sessionStart) }} - {{ formatTime(session.sessionEnd) }}</b>
					</p>
					<p v-if="session.customPickupLocation?.name || (selectedTimeSlot.location?.name && selectedTimeSlot.location?.locationID !== 'none')">
						{{ session.customPickupLocation?.name || selectedTimeSlot.location?.name }}
					</p>
				</div>
				<div
					class="reservation-booking-group__icon"
					v-if="session.sessionType === 'observe'"
				>
					<ion-icon
						color="dark"
						src="/assets/icon-eye.svg"
					/>
				</div>
				<div
					v-if="session.sessionType === 'drive'"
					class="reservation-booking-group__icon"
				>
					<ion-icon
						color="dark"
						src="/assets/icon-gauge-low.svg"
					/>
				</div>
			</div>
			<div class="reservation-confirmation-modal__buttons">
				<ion-button
					@click="closeModal"
					expand="block"
					class="cancel-button"
				>
					CANCEL
				</ion-button>
				<ion-button
					@click="cancelReservation"
					expand="block"
					class="confirm-button"
				>
					CONFIRM
				</ion-button>
			</div>
		</div>
	</ion-modal>
</template>

<script lang="ts">
import { IonModal } from '@ionic/vue';
import {
	alertCircle,
} from 'ionicons/icons';
import { defineComponent, PropType, ref } from 'vue';
import { toast } from '@/core/toast/Toast';
import { useStore } from "vuex";
import omitDeep from 'omit-deep-lodash';
import { useMutation } from '@vue/apollo-composable';
import {
	CANCEL_APPOINTMENT_MUTATION,
} from '@/core/api/schedulerApi';
import { api } from "@/core/api/api";
import envConfig from '@/core/config/env.config';
import { getSessionsTimeSlotLoggingDetails, formatTime } from '@/core/util/helpers';
import { SessionDetails, TimeSlotVM } from '@/core/store/store';

export default defineComponent({
	components: { IonModal },
	props: {
		selectedTimeSlot: {
			type: Object as PropType<TimeSlotVM>,
			required: true,
		},
		cancelModalOpen: {
			type: Boolean,
			default: false,
		},
	},
	setup(pros, context) {
		const store = useStore();

		const logData = (type, status, details) => {
			api.logMobileActivity({
				institution: store.getters.institutionSettings.slug,
				event: type,
				affectedUserID: store.getters.user.userID,
				additionalData:{
					email: store.getters.user.email,
					activeUser: store.getters.selectedUser,
					currentAppVersions: store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status,
					details
				}
			});
		}

		const cancelAppointmentVariables = ref({
			sessions: [],
			date: null,
			institution: null,
			timeSlotID: null,
			userID: null,
		});

		const cancelAppointment = useMutation(
			CANCEL_APPOINTMENT_MUTATION,
			() => {
				const loggingDetails = getSessionsTimeSlotLoggingDetails(cancelAppointmentVariables.value.sessions, store.getters.institutionSettings);

				logData('Canceled Appointment', 'Success', loggingDetails);

				return {
					variables: omitDeep(cancelAppointmentVariables.value, 'customPickupLocation')
				}
			});
		cancelAppointment.onDone(result => {
			const successfulCreditTransaction = result.data.cancelAppointment?.transactions.find(transaction => transaction.success && transaction?.__typename === 'Credit');
			const isDebitTransactions = result.data.cancelAppointment?.transactions.find(transact => transact.__typename === 'Debit');
			const unsuccessfulCreditTransaction = result.data.cancelAppointment?.transactions.find(transaction => !transaction.success && transaction?.__typename === 'Credit');
			const noCreditTransactionAttempted = (!successfulCreditTransaction && !unsuccessfulCreditTransaction);

			if (successfulCreditTransaction && isDebitTransactions) {
				toast.success({
					message: `
						Your appointment has been canceled, and the late cancelation fee payment was successful.
					`,
					duration: 7500,
					position: 'top'
				});
			}
			if (noCreditTransactionAttempted && isDebitTransactions) {
				toast.warning({
					message: `
						Your appointment has been canceled, but you will need to pay your cancelation fee.
						Please contact ${store.getters.institutionSettings.name} to make your payment.
					`,
					duration: 7500,
					position: 'top'
				});
			}
			if (unsuccessfulCreditTransaction && isDebitTransactions) {
				toast.error({
					message: `
						Your appointment has been canceled, but the late cancelation fee payment was unsuccessful.
						Please contact ${store.getters.institutionSettings.name} to make your payment.
					`,
					duration: 7500,
					position: 'top'
				});
			}
			context.emit('refetchTimeSlots');
		});

		cancelAppointment.onError(error => {
			console.error(error)
			const loggingDetails = getSessionsTimeSlotLoggingDetails(cancelAppointmentVariables.value.sessions, store.getters.institutionSettings);

			logData('Canceled Appointment', 'Failure', loggingDetails);

			toast.error({
				message: error.message,
				duration: 7500,
				position: 'top'
			});
			context.emit('refetchTimeSlots');
		});

		return {
			alertCircle,
			store,
			formatTime,
			logData,
			cancelAppointment,
			cancelAppointmentVariables,
		}
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		omitDeep,
		closeModal() {
			this.$emit('closeCancelModal');
		},
		async cancelReservation() {
			try {
				this.loading = true;
				this.$emit('setLoading', this.loading);

				this.cancelAppointmentVariables = this.omitDeep({
					sessions: this.orderReservations,
					date: this.selectedTimeSlot.instance.date,
					institution: this.store.getters.user.institutions[0],
					timeSlotID: this.selectedTimeSlot.timeSlotID,
					userID: this.store.getters.selectedUser || this.store.getters.user.userID,
				}, '__typename');
				await this.cancelAppointment.mutate();

				this.loading = false;
				this.$emit('setLoading', this.loading);
				this.closeModal();
			} catch (e) {
				this.loading = false;
				this.$emit('setLoading', this.loading);
				this.closeModal();
				console.error(e);
			} finally {
				this.store.dispatch('getStudents', this.store.getters.user.userID);
			}
		},
		isInCancellationPeriod(sessions: SessionDetails[]) {
			const startOfFirst = new Date(sessions[0].sessionStart);

			const cancellationPeriod = this.store.getters.institutionSettings.schedulerSettings.minimumCancellationTime;

			// If appointment slot - current time < a day in milliseconds.
			return startOfFirst.getTime() < Date.now() + cancellationPeriod
		},
	},
	computed: {
		orderReservations() {
			return this.selectedTimeSlot.instance.sessions
				.filter(sesh => sesh.userID === this.store.getters.selectedUser)
				.sort((a, b) => new Date(a.sessionStart).getTime() - new Date(b.sessionStart).getTime())
				.map(sesh => {
					return {
						sessionID: sesh.sessionID,
						sessionType: sesh.sessionType,
						sessionStart: sesh.sessionStart,
						sessionEnd: sesh.sessionEnd,
						customPickupLocation: sesh.customPickupLocation,
					};
				});
		},
		cancelationMessage() {
			if (this.isInCancellationPeriod(this.selectedTimeSlot.instance.sessions)) {
				const minimumCancellationTimeHours = this.store.getters.institutionSettings.schedulerSettings.minimumCancellationTime / 1000 / 60 / 60;
				const readableTime = new Intl.NumberFormat('en-US', { style: 'unit', unit: 'hour', unitDisplay: 'long' }).format(minimumCancellationTimeHours);

				return `There will be a $${this.store.getters.institutionSettings.schedulerSettings.cancellationFee} charge since this is within ${readableTime} of your appointment.`;
			}
			return '';
		}
	}
});
</script>

<style lang="scss" scoped>
.reservation-confirmation-modal {
	--max-width: 80%;
	--border-radius: 6px;
	--max-height: 90%;
}

ion-content::part(scroll) {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px;
}

.reservation-confirmation-modal {
	--background: var(--ion-color-primary-tint);
	--height: auto;
	--overflow: auto;

	&__container {
		padding: 15px;
	}

	&__header {
		color: var(--ion-color-light, #FFF);
		text-align: center;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 147%;
		letter-spacing: 0.78px;
	}

	&__late-cancel-message {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		justify-content: center;

		p {
			font-size: 12px;
			line-height: 147%;
			letter-spacing: 0.78px;
			color: var(--ion-color-light, #FFF);
		}

		ion-icon {
			font-size: 30px;
			min-width: 30px;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		width: 100%;


		ion-button {
			width: 100%;
			font-size: 13px;
			font-weight: 700;
			line-height: 147%;
			letter-spacing: 0.78px;
			--border-radius: 6px;
		}

		.cancel-button {
			--background: var(--ion-color-primary-lighten-3, #6B7985);
		}
		.confirm-button {
			--background: var(--ion-color-danger);
		}
	}
}

.reservation-booking-group {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #FFF;
	padding: 5px;

	&__first-session {
		margin-top: 15px;
	}

	&__last-session {
		margin-bottom: 15px;
	}

	&__icon {
		border-radius: 150px;
		background: #FFF;
		width: 50px;
		height: 50px;

		ion-icon {
			height: 40px;
			width: 40px;
			padding: 5px;
		}
	}

	&__text {
		display: flex;
		font-size: 12px;
		font-weight: 700;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
	}
}
</style>
<template>
	<ion-page id="home">
		<app-header @studentChange="studentChanged" />
		<progress-message
			:icon="alarm"
			color="warning"
			:message="`You have ${eLearningDaysFromLockDate} day${eLearningDaysFromLockDate === 1? '' : 's'} to complete your course before it locks on ${format(classroomUserProgress.lockDate, 'MMM do, yyyy')}`"
			:isButton="true"
			type="eLearningCountdownWarning"
			v-if="showeLearningExtensionWarning"
		></progress-message>
		<ion-loading v-if="extensionLoading" show-backdrop />
		<progress-message
			:icon="lockClosed"
			color="danger"
			:message="`You have exceeded your ${store.getters.selectedSyllabus?.duration ? `${store.getters.selectedSyllabus?.duration} day` : ''} time limit to complete your course and your course is now locked.`"
			v-if="eLearningExtensionNeeded"
		>
			<template v-slot>
				<ion-button
					color="tertiary"
					@click="promptExtension()"
				>
					Learn More
				</ion-button>
			</template>
		</progress-message>
		<progress-message
			:isCustomIcon="true"
			color="warning"
			message="Your browser is out of date! You may experience issues as you use the app."
			:isButton="false"
			type="eLearningCountdownWarning"
			v-if="!isCompatibleBrowser"
		>
			<template v-slot:icon>
				<m-d-i-alert-rhombus-outline fill="white"></m-d-i-alert-rhombus-outline>
			</template>
		</progress-message>
		<NotificationCard
			:notification="notification"
			v-for="(notification, index) in notificationsForHome"
			:key="index"
		/>
		<ion-content :fullscreen="true">
			<template v-if="isAuthenticated">
				<update-available-card v-if="store.getters.hasUpdateAvailable" />
				<ion-grid v-if="loading || userAppointmentsQueryLoading" class="ion-padding">
					<ion-row>
						<ion-col>
							<div class="loading-heading">
								<ion-thumbnail class="loading-heading__icon" style="height: 30px; width: 30px;" v-if="loading || userAppointmentsQueryLoading">
									<ion-skeleton-text :animated="true"></ion-skeleton-text>
								</ion-thumbnail>
								<h2 class="loading-heading__title ion-no-margin">
									<ion-skeleton-text animated style="width: 100px; height: 30px;"></ion-skeleton-text>
								</h2>
							</div>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12">
							<ion-card class="loading-card" style="background-color: white;">
								<ion-card-header color="transparent" class="loading-card__header ion-no-padding">
									<ion-card-title>
										<ion-skeleton-text animated style="width: 100px; height: 40px;"></ion-skeleton-text>
									</ion-card-title>

								</ion-card-header>
								<ion-card-content class="loading-card__text ion-no-padding">
									<ion-text>
										<ion-skeleton-text animated style="width: 60%; height: 20px;"></ion-skeleton-text>
									</ion-text>
								</ion-card-content>
								<ion-card-content class="ion-no-padding">
									<ion-button expand="block" disabled></ion-button>
								</ion-card-content>
							</ion-card>
						</ion-col>
					</ion-row>
				</ion-grid>
				<swiper
					class="ion-padding-bottom"
					:class="{ 'no-course-content-container':(!store.state.hasCourse || !store.state.courseIsActive)}"
					:enabled="students.length > 1"
					:modules="swiperModules"
					@slideChange="slideChange()"
					@swiper="onSwiper"
					v-if="students && !loading && !userAppointmentsQueryLoading"
					:virtualIndex="1"
				>
					<swiper-slide v-for="student in students" :key="student.userID" :data-userid="student.userID" ref="studentSwiperSlide">
						<ion-grid v-if="!store.state.hasCourse || !store.state.courseIsActive " class="ion-padding no-course-content">
							<ion-row>
								<ion-col>
									<ion-icon :ios="informationCircle" :md="informationCircle" size="large" color="primary"></ion-icon>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col v-if="!store.state.hasCourse">
									<ion-label>{{ (isParent) ? 'Your selected student is not currently registered for any courses.' : 'You are not currently registered for any courses.' }}</ion-label>
								</ion-col>
								<ion-col v-if="!store.state.courseIsActive && store.state.hasCourse">
									<ion-label>{{ (isParent) ? 'Your selected student account is currently not active.' : 'Your account is currently not active.' }}</ion-label>
								</ion-col>
							</ion-row>
							<ion-row v-if="!store.state.courseIsActive && store.state.hasCourse">
								<ion-col>
									<ion-label>If you believe this is in error please contact us through one of the options below.</ion-label>
								</ion-col>
							</ion-row>
							<ion-row v-if="!store.state.courseIsActive && store.state.hasCourse">
								<ion-col>
									<ion-button class="support-button" color="tertiary" expand="block" :href="'tel:' + store.getters.institutionSettings.phone" part="native">
										<ion-icon :icon="call" slot="start"></ion-icon>
										{{ store.getters.institutionSettings.phone }}
									</ion-button>
								</ion-col>
							</ion-row>
							<ion-row v-if="!store.state.courseIsActive && store.state.hasCourse">
								<ion-col>
									<ion-button class="support-button" color="tertiary" expand="block" :href="'mailto:' + store.getters.institutionSettings.email" part="native">
										<ion-icon :ios="mail" :md="mail" slot="start"></ion-icon>
										{{ store.getters.institutionSettings.email }}
									</ion-button>
								</ion-col>
							</ion-row>
						</ion-grid>
						<ion-grid v-if="(store.state.hasBTWCourse || store.state.haseLearningCourse) && store.state.hasCourse && store.state.courseIsActive" class="ion-padding">
							<ion-row v-if="showBilling()">
								<ion-col>
									<heading
										mdiIcon="mdiCashMultiple"
										:noTopMargin="true"
										:title="'Balance Due'" />
								</ion-col>
							</ion-row>
							<ion-row v-if="showBilling()" class="last-child-margin">
								<ion-col size="12" v-for="({balanceDue, studentCourseStatus, studentCourseID}) in balanceDueMapFiltered" :key="studentCourseID">
									<balance-card
										v-if="balanceDue < 0 || ['registered', 'completed'].includes(studentCourseStatus)"
										mdiIcon ="mdiCash"
										:balance="balanceDue"
										:studentCourseID="studentCourseID"
										:courseName="getCourseName(studentCourseID)"
									/>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col>
									<heading
										:icon="library"
										:noTopMargin="true"
										:title="'Course Progress'" />
								</ion-col>
								<ion-chip v-if="store.state.btwCourseOnHold || store.state.eLearningCourseOnHold" class="on-hold" @click="showMessage(this.store.getters.onHoldMessage)">
									<ion-icon :icon="alertCircleOutline" color="light"></ion-icon>
									<ion-label>On hold</ion-label>
								</ion-chip>
							</ion-row>
							<ion-row>
								<ion-col v-if="store.state.courseComplete">
									<ion-card color="success" :icon="ribbon" class="completed-course">
										<ion-icon :ios="ribbon" :md="ribbon" size="large"></ion-icon>
										<h1>Course Complete!</h1>
									</ion-card>
								</ion-col>
								<ion-col size="12" v-if="hasELearningAccess && classroomUserProgress && store.state.haseLearningCourse && !store.state.courseComplete">
									<counter-card
										:amount="hoursCompleteDisplay"
										:completed="Boolean(classroomUserProgress?.completedDate)"
										:icon="book"
										:title="'Lesson Progress'"
										:status="(dailyLimitMet || (completedAllButLastLesson && eLearningMinimumFinishDate)) ? 'warning' : ''"
										:linkButton="(!dailyLimitMet) ? lessonCounterCardButton : {}"
										:message="lessonCounterCardMessage"
										:infoText="(isParent) ? 'Parent accounts can only view student progress and results. To view eLearning content please login as a student.' : ''"
									/>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12" v-if="store.state.hasBTWCourse">
									<ion-card button @click="store.commit('showSettingsModal', {type: 'userDetails', user: store.getters.selectedUser});" class="missing-permit-card" color="warning" v-if="!student.permitIssued">
										<ion-card-header class="ion-no-padding">
											<ion-card-title><ion-icon :icon="alertCircleOutline"></ion-icon>No drivers permit entered</ion-card-title>
										</ion-card-header>
									</ion-card>
									<date-card
										:date="student.earliestDriversTestDate"
										title="Earliest drivers test date"
										v-else
									/>
								</ion-col>
								<ion-col size="12" :size-sm="!store.getters.observeDisabled ? 6 : 12" v-if="store.state.hasBTWCourse && !store.state.courseComplete">
									<counter-card
										:amount="student.driveHours.toString()"
										amountLabel="h"
										:completed="student.driveHours >= student.requiredDriveHours"
										:icon="speedometer"
										:requiredAmount="student.requiredDriveHours.toString()"
										:title="'Behind the Wheel'"
									/>
								</ion-col>
								<ion-col size="12" size-sm="6" v-if="store.state.hasBTWCourse && !store.state.courseComplete && !store.getters.observeDisabled">
									<counter-card
										:amount="student.observeHours.toString()"
										amountLabel="h"
										:completed="student.observeHours >= student.requiredObserveHours"
										:icon="eye"
										:requiredAmount="student.requiredObserveHours.toString()"
										:title="'Observation'"
									/>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col v-if="student.notes.userApp || (store.state.hasBTWCourse && !store.state.courseComplete)">
									<heading :icon="calendar" title="Scheduled Appointments" />
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12">
									<alert-bar
										v-for="note in student.notes.userApp"
										:key="note.noteID"
										:htmlText="note.note"
										:type="note.type"
									/>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col v-if="store.state.hasBTWCourse && !store.state.courseComplete">
									<template v-if="upcomingTimeSlots.length > 0">
										<time-slot-item
											type="home"
											:timeSlot="timeSlot"
											:studentID="studentID"
											v-for="{timeSlot, studentID} in upcomingTimeSlots.filter(({studentID}) => studentID == student.userID)"
											:key="timeSlot.timeSlotID"
										/>
									</template>
									<div class="ion-margin ion-text-center" v-else>
										<ion-icon class="ion-margin-bottom" :icon="calendarClearOutline" size="large"></ion-icon>
										<p class="ion-margin-bottom">You haven't scheduled<br />any appointments yet.</p>
										<p>Once you have scheduled appointments they will appear here.</p>
									</div>
								</ion-col>
							</ion-row>
						</ion-grid>
					</swiper-slide>
				</swiper>
			</template>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	alertController,
	IonButton,
	IonCard,
	IonChip,
	IonContent,
	IonIcon,
	IonItem,
	IonPage,
	IonLoading,
	IonicSlides,
	IonSkeletonText
} from "@ionic/vue";
import {
	alarm,
	lockClosed,
	alertCircleOutline,
	pin,
	mail,
	notifications,
	book,
	call,
	calendar,
	calendarClearOutline,
	cash,
	checkmarkCircle,
	chevronBackOutline,
	chevronForwardOutline,
	eye,
	library,
	medal,
	peopleCircle,
	personCircle,
	speedometer,
	informationCircle,
	callOutline,
	ribbon,
	timerOutline
} from "ionicons/icons";
import { ref, computed } from 'vue';
import AppHeader from '@/view/components/AppHeader.vue';
import TimeSlotItem from "@/view/components/TimeslotItem.vue";
import { mapGetters, useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { SessionStatus } from '../../core/SessionStatus';
import CounterCard from '@/view/components/CounterCard.vue';
import BalanceCard from '@/view/components/BalanceCard.vue';
import DateCard from '@/view/components/DateCard.vue';
import Heading from '@/view/components/Heading.vue';
// Swiper specific imports. Only loading them on the pages that need it.
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/vue/css/ionic-swiper.css';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { differenceInMinutes, format, isFuture, parse } from 'date-fns';
import UpdateAvailableCard from "../components/UpdateAvailableCard.vue";
import ProgressMessage from '@/view/components/ProgressMessage.vue';
import MDIAlertRhombusOutline from "../components/icons/MDIAlertRhombusOutline.vue";
import AlertBar from "@/view/components/AlertBar.vue";
import NotificationCard from "@/view/components/cards/NotificationCard.vue";
import { useQuery } from "@vue/apollo-composable";
import { SCHEDULED_TIME_SLOTS_QUERY, TIME_SLOT_UPDATED_SUB } from "@/core/api/schedulerApi";
import { createInstanceVMs } from "./scheduler/createTimeSlotVMs";

export default {
	name: "Home",
	components: {
		AppHeader,
		AlertBar,
		BalanceCard,
		CounterCard,
		DateCard,
		Heading,
		alertController,
		IonButton,
		IonCard,
		IonChip,
		IonContent,
		IonIcon,
		IonItem,
		IonPage,
		IonLoading,
		IonSkeletonText,
		Swiper,
		SwiperSlide,
		TimeSlotItem,
		UpdateAvailableCard,
		ProgressMessage,
		MDIAlertRhombusOutline,
		NotificationCard
	},
	data() {
		return {
			loading: true,
			studentSwiper: null,
			totalObserveTime: 0,
			totalDriveTime: 0,
			user: {
				correctiveLensesRequired: '',
				permitIssued: '',
				permitIssuingState: '',
				permitNumber: '',
				userID: '',
			},
			extensionLoading: false
		}
	},
	setup() {
		const queryLoading = ref(true);
		const router = useRouter();
		const store = useStore()

		const userAppointmentsQuery = useQuery(
			SCHEDULED_TIME_SLOTS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
				userIDs: store.getters.studentIDs
			}),
			{
				fetchPolicy: 'network-only',
				enabled: computed(() => !!store.getters.isAuthenticated)
			}
		);

		userAppointmentsQuery.onResult(result => {
			if (result?.data?.timeSlotsWithUserSessionInstances) store.commit('setUserAppointmentsData', result.data.timeSlotsWithUserSessionInstances);
		})

		userAppointmentsQuery.subscribeToMore({
			document: TIME_SLOT_UPDATED_SUB,
			variables: {institution: store.getters.user.institutions[0]},
			updateQuery: (prev, {subscriptionData}) => {
				const tmpTimeSlots = [...(prev?.timeSlotsWithUserSessionInstances||[])];
				const incomingTimeSlotContainsStudentIDs = subscriptionData.data?.timeSlotUpdated?.instances?.some(inst => {
					return inst.sessions.some(session => store.getters.studentIDs?.includes(session.userID));
				});
				const index = tmpTimeSlots.findIndex(timeSlot => timeSlot.timeSlotID === subscriptionData.data.timeSlotUpdated.timeSlotID);
				if (index === -1 && incomingTimeSlotContainsStudentIDs) {
					tmpTimeSlots.push(subscriptionData.data.timeSlotUpdated);
				} else if (incomingTimeSlotContainsStudentIDs) {
					tmpTimeSlots[index] = subscriptionData.data.timeSlotUpdated;
				}
				return {timeSlotsWithUserSessionInstances: tmpTimeSlots.filter(ts => ts.instances?.length)};
			},
		});

		return {
			alarm,
			alertCircleOutline,
			book,
			call,
			calendar,
			calendarClearOutline,
			cash,
			checkmarkCircle,
			chevronBackOutline,
			chevronForwardOutline,
			eye,
			library,
			informationCircle,
			ribbon,
			callOutline,
			mail,
			medal,
			notifications,
			peopleCircle,
			personCircle,
			pin,
			queryLoading,
			lockClosed,
			timerOutline,
			router,
			speedometer,
			store,
			swiperModules: [IonicSlides, Pagination, Navigation],
			userAppointmentsQuery
		};
	},
	methods: {
		differenceInMinutes,
		format,
		async showMessage(message) {
			const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Close',
						handler: () => {
							alert.dismiss();
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'Administrative Hold',
				message: message
			});
			alert.present();
		},
		onSwiper(swiper) {
			this.studentSwiper = swiper;
			this.studentChanged();
		},
		parse,
		slideChange() {
			let activeSlideIndex = this.studentSwiper.activeIndex;
			if (this.students[activeSlideIndex].userID != this.store.getters.selectedUser) {
				// The ion-select in AppHeader will take care of data setup, after the selected user is set
				this.store.commit('setSelectedUser', this.students[activeSlideIndex].userID);
			}
		},
		studentChanged(data) {
			if(this.studentSwiper && this.store.getters.selectedUser) {
				let studentIndex = this.students.map((student) => student.userID).indexOf(data ? data : this.store.getters.selectedUser);
				this.studentSwiper.slideTo(studentIndex);
			}
		},
		studentTimeSlots(userID, upcomingTimeSlots) {
			return upcomingTimeSlots.filter(({student}) => {
				return student === userID;
			});
		},
		getCourseName(courseID) {
			for(const student of this.students) {
				const currentCourse = student.courses.find(studentCourse => studentCourse.studentCourseID === courseID);
				if(currentCourse){
					return currentCourse?.course?.name || '';
				}
			}
		},
		showBilling() {
			return this.store.getters.students.length &&
				this.featureFlags.inAppBilling &&
				this.institutionSettings?.moduleSettings?.registration &&
				this.balanceDue &&
				this.balanceDueMapFiltered.some(balance => balance.balanceDue > 0 || ['registered', 'completed'].includes(balance.studentCourseStatus)) &&
				JSON.stringify(this.balanceDue) !== '{}';
		},
		async promptExtension() {
			let handler;

			if (this.store.getters.selectedSyllabus?.extensionSettings?.immediatePaymentRequired && this.store.getters.featureFlags?.inAppBilling) {
				handler = () => {
					this.store.commit('showSettingsModal', {type: 'billing', requirePayment: true, paymentAmount: this.store.getters.selectedSyllabus.extensionSettings.price, paymentCallback: ({id}) => this.store.dispatch('extendELearning', id), addCorrespondingFee: {type: 'FEE_ELEARNING_EXTENSION', description: 'eLearning Extension'}})
				}
			} else {
				handler = async () => {
					this.extensionLoading = true;
					await this.store.dispatch('extendELearning', 'n/a')
					.finally(() => {
						this.extensionLoading = false;
					})
				}
			}

			const buttons = [{
				text: 'Cancel',
				role: 'cancel'
			}]

			if (this.store.getters.selectedSyllabus.extensionSettings?.appExtensionAllowed) {
				buttons.push({
					text: 'Extend',
					handler
				});
			}

			const alert = await alertController.create({
				backdropDismiss: true,
				buttons,
				cssClass: 'eLearning-extension-dialog',
				message: this.extensionPromptMessage
			});
			await alert.present();
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'institutionSettings', 'featureFlags']),
		balanceDue() {
			return this.store.getters.balanceDueMap;
		},
		balanceDueMapFiltered() {
			return Object.keys(this.balanceDue).map(key => {
				const studentCourseStatusForKey = this.activeStudent?.courses?.find(course => course.studentCourseID === key)?.studentCourseStatus;
				return {
					balanceDue: this.balanceDue[key],
					studentCourseStatus: studentCourseStatusForKey,
					studentCourseID: key
				};
			});
		},
		userAppointmentsQueryLoading() {
			return this.userAppointmentsQuery?.loading?.value;
		},
		completedAllButLastLesson() {
			return this.store.getters.completedAllButLastLesson;
		},
		completedLessons() {
			return this.classroomUserProgress?.lessons?.filter(l => l.completeDate).length || 0;
		},
		hasELearningAccess() {
			return this.institutionSettings?.moduleSettings?.eLearning
		},
		eLearningMinimumFinishDate() {
			return this.store.getters.eLearningMinimumFinishDate ?? false;
		},
		dailyLimitMet() {
			return this.store.getters.dailyELearningLimitMet?.formatted || false;
		},
		eLearningExtensionNeeded() {
			return this.store.getters.eLearningExtensionNeeded;
		},
		eLearningHasBeenExtended() {
			return this.store.getters.eLearningHasBeenExtended;
		},
		eLearningDaysFromLockDate() {
			return this.store.getters.eLearningDaysFromLockDate;
		},
		showeLearningExtensionWarning() {
			return this.store.getters.showeLearningExtensionWarning;
		},
		isCompatibleBrowser() {
			return this.store.getters.isCompatibleBrowser;
		},
		isParent() {
			return this.store.getters.user.isParent ?? false;
		},
		lessonCounterCardButton() {
			if (this.isParent) {
				return {
					color: 'tertiary',
					path: `/lessons/`,
					text: 'View Student Progress'
				};
			}

			if (
				this.dailyLimitMet
				|| (this.completedAllButLastLesson && this.eLearningMinimumFinishDate)
				|| !this.classroomUserProgress?.lessons
			) { return {}; }

			const lessonInProgress = this.classroomUserProgress?.syllabus?.lessons.find((l) => !this.classroomUserProgress.lessons.find((lp) => lp.lessonID === l.lessonID && lp.completeDate));

			if (!lessonInProgress) return {};

			const lessonProgress = this.classroomUserProgress?.lessons.find((lp) => lp.lessonID == lessonInProgress.lessonID);

			const topicsCompleted = lessonProgress?.progressItems?.filter((pi) => pi.type === 'TOPIC') ?? [];

			if (lessonInProgress.inPerson || this.eLearningExtensionNeeded) {
				return {
					text: 'View Lessons',
					color: 'tertiary',
					path: `/lessons/`,
					lesson: lessonInProgress.lessonID
				}
			}
			if (lessonInProgress.topics.length && topicsCompleted.length < lessonInProgress.topics.length) {
				const inProgressTopic = lessonInProgress.topics.find((t) => {
					return !topicsCompleted.some((tc) => tc.reference === t.topicID)
				});
				if (!inProgressTopic) {
					console.error('Missing in progress topic');
					return {};
				}

				const resourcesComplete = lessonProgress?.progressItems?.find((pi) => pi.type === 'RESOURCES' && pi.reference === inProgressTopic.topicID);

				if (resourcesComplete) {
					return {
						text: 'Begin Quiz',
						color: 'tertiary',
						path: `/quiztest/${lessonInProgress.lessonID}/${inProgressTopic.topicID}`,
						lesson: lessonInProgress.lessonID
					};
				} else {
					const latestCompletedResourceIndex = inProgressTopic.resources.findLastIndex((resource) => !!lessonProgress?.progressItems?.find((pi) => pi.reference === resource.resourceID));
					const latestBreakResourceIndex = inProgressTopic.resources.findLastIndex((resource, index) => resource.content.type === 'BREAK' && index <= latestCompletedResourceIndex);
					const inProgressResource = inProgressTopic.resources[latestBreakResourceIndex+1];
					let path = `/lessons/${lessonInProgress.lessonID}/${inProgressTopic.topicID}`;
					if (inProgressResource) {
						path += `/${inProgressResource.resourceID}`;
					}

					return {
						text: 'View Lesson',
						color: 'tertiary',
						path,
						lesson: lessonInProgress.lessonID
					};
				}
			} else {
				if (lessonInProgress.hasTest) {
					if (lessonProgress?.progressItems?.find((i) => i.type === 'TEST')) {
						return {
							text: 'View Lesson',
							color: 'tertiary',
							path: `/summary/${lessonInProgress.lessonID}`,
							lesson: lessonInProgress.lessonID
						};
					} else {
						return {
							text: 'Begin Test',
							color: 'tertiary',
							path: `/quiztest/${lessonInProgress.lessonID}`,
							lesson: lessonInProgress.lessonID
						};
					}
				} else {
					return {
						text: 'View Lesson',
						color: 'tertiary',
						path: `/summary/${lessonInProgress.lessonID}`,
						lesson: lessonInProgress.lessonID
					};
				}
			}
		},
		lessonCounterCardMessage() {
			if (this.completedAllButLastLesson && this.eLearningMinimumFinishDate) {
				return {
					icon: alarm,
					text: (this.isParent) ? 'Your student is doing great! They will need to wait until ' + format(this.eLearningMinimumFinishDate, 'MMMM d, yyyy') + ' to complete their final lesson to meet Wisconsin DOT guidelines. Have them continue their lessons in their app after ' + format(this.eLearningMinimumFinishDate, 'MMMM d, yyyy') + '.' : 'Nice work so far! You will need to wait until ' + format(this.eLearningMinimumFinishDate, 'MMMM d, yyyy') + ' to complete your final lesson to meet Wisconsin DOT guidelines.'
				}
			}

			if (!this.classroomUserProgress) { return {}; }

			if (this.classroomUserProgress?.completedDate) {
				return {
					icon: medal,
					text: (this.isParent) ? 'Your student has successfully completed their classroom course!' : 'You have successfully completed your classroom course!'
				};
			}

			if (this.dailyLimitMet) {
				return {
					icon: alarm,
					text: (this.isParent) ? `Your student has met their maximum of ${this.dailyLimitMet} of lessons per day. They should take a well earned break and continue in their app tomorrow!` : `You may only complete ${this.dailyLimitMet} of learning per day. The next topic would take you over that limit. Take a well earned break and come back tomorrow!`
				}
			}

			if (this.eLearningExtensionNeeded) {
				return {
					icon: lockClosed,
					text: (this.isParent) ? 'Your student has exceeded their time limit to complete their course. The course is now locked.' : 'You have exceeded your time limit to complete your course. The course is now locked.'
				}
			}

			if (this.eLearningHasBeenExtended) {
				return {
					icon: timerOutline,
					text: (this.isParent) ? `Your student's course is currently extended. They have until ${format(this.classroomUserProgress.lockDate, 'MMM do, yyyy')} to complete the course.` : `Your course is currently extended. You have until ${format(this.classroomUserProgress.lockDate, 'MMM do, yyyy')} to complete the course.`
				}
			}

			return {};
		},
		activeStudent() {
			return this.students.find(s => s.userID === this.store.getters.selectedUser);
		},
		studentClassroomCourse() {
			if (!(this.store.getters.selectedUser && this.students && this.store.getters.selectedUserClassroomStudentCourseID)) { return {}}

			const selectedUserClassroomStudentCourseIDSplit = this.store.getters.selectedUserClassroomStudentCourseID.split('::');
			const studentCourseID = selectedUserClassroomStudentCourseIDSplit[2];
			const studentCourse = this.activeStudent.courses.find(c => c.studentCourseID === studentCourseID);

			return studentCourse;
		},
		minutesComplete() {
			if (!this.classroomUserProgress) { return 0; }
			return this.classroomUserProgress.minutesFromLessons + this.classroomUserProgress.minutesInPerson;
		},
		hoursComplete() {
			return Math.floor(this.minutesComplete / 60)
		},
		hoursCompleteDisplay() {
			return `${this.hoursComplete > 0 ? `${this.hoursComplete}h ` : ''}${this.minutesComplete % 60}m / ${this.numHoursDisplay}`;
		},
		numMinutes() {
			let totalMinutes = 0;
			if (this.classroomUserProgress && this.classroomUserProgress.syllabus) {
				totalMinutes += this.classroomUserProgress.syllabus.lessons.map((lesson) => {
					return (lesson.topics || []).map(({estimatedMinutes}) => estimatedMinutes).reduce((a,b)=>a+b, 0);
				}).reduce((a,b)=>a+b, 0);
			}
			if (this.studentClassroomCourse?.course?.instances && this.studentClassroomCourse?.course?.instances.length) {
				const regularMinutes = this.studentClassroomCourse.course.instances.map(({date, start, end}) => {
					return differenceInMinutes(new Date(end), new Date(start));
				}).reduce((a,b) => a+b, 0)

				const customInstancesMinutes = (this.studentClassroomCourse.rollCall || []).filter(({isCustomInstance}) => isCustomInstance).map(({courseInstance}) => {
					return differenceInMinutes(new Date(courseInstance.end), new Date(courseInstance.start));
				}).reduce((a,b) => a+b, 0);

				totalMinutes += regularMinutes + customInstancesMinutes;
			}

			return totalMinutes;
		},
		numHoursDisplay() {
			const minutes = this.numMinutes % 60;
			const hours = Math.round((this.numMinutes - minutes) / 60);
			return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m` : ''}`;
		},
		students() {
			return this.store.state?.students || [];
			
			return returnStudents;
		},
		classroomUserProgress() {
			return this.store.getters.studentsClassroomProgress.find(scp => scp.userID === this.store.getters.selectedUser)?.classroomUserProgress || false;
		},
		upcomingTimeSlots() {
			return this.store.getters.studentIDs.flatMap((studentID) => {
				return createInstanceVMs(this.store.getters.userAppointmentsData||[], studentID).filter((timeSlot) => {
					return timeSlot.isScheduled && !timeSlot.isPast
				}).map((timeSlot) => ({
					timeSlot,
					studentID
				}));
			});
		},
		extensionPromptMessage() {
			let message = `<p>You did not complete the course within the ${this.store.getters.selectedSyllabus.duration} day course period.</p>`;

			if (this.store.getters.selectedSyllabus.extensionSettings?.appExtensionAllowed) {
				let priceString = this.store.getters.selectedSyllabus.extensionSettings.price.toFixed(2);
				if (priceString.endsWith(".00")) priceString = priceString.slice(0, -3);

				message += `<p>You may extend your course by an additional ${this.store.getters.selectedSyllabus.extensionSettings.additionalDuration} days but this will cost an extra $${priceString}.</p><div><span>+ Add ${this.store.getters.selectedSyllabus.extensionSettings.additionalDuration} days </span><span>$ ${priceString}</span></div>`;
			} else {
				message += `<p>Please contact ${this.store.getters.institutionSettings.name} to extend your eLearning course</p>`
			}

			return message;
		},
		notificationsForHome() {
			return this.store.getters.notifications.filter(({showOnHome}) => {
				return showOnHome;
			}).sort((a, b) => {
					return b.startDate - a.startDate;
			}).map((notification) => {
				const {startDate, ...rest} = notification;

				return rest;
			});
		}
	},
	async ionViewWillEnter() {
		this.loading = true;
		await Promise.all([
			this.store.dispatch('getFeatureFlags'),
			this.store.dispatch('getBrowserSupport'),
			this.store.dispatch('getInstitutionSettings'),
			this.store.dispatch('getStudents', this.$store.state.auth.user.userID),
			this.store.dispatch('getDailyELearningLimit', this.store.state.selectedUser),
			this.store.dispatch('checkBalanceDue', this.store.getters.students.find(s => s.userID === this.store.state.selectedUser))
		]);

		this.loading = false;
	}
}
</script>

<style lang="scss" scoped>
#home {
	--background: var(--ion-color-grey-darken-1);
}

ion-menu-button {
	color: var(--ion-color-primary);
}

#container {
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

#container strong {
	font-size: 20px;
	line-height: 26px;
}

#container p {
	font-size: 16px;
	line-height: 22px;
	color: #8c8c8c;
	margin: 0;
}

#container a {
	text-decoration: none;
}

.swiper {
	padding-bottom: 15px;
}

.missing-permit-card {
	margin: 0;
	padding: 10px 15px;

	ion-card-title {
		align-items: center;
		display: flex;
		font-size: 1em;
		justify-content: center;

		ion-icon {
			font-size: 1.25em;
			margin-right: 5px;
		}
	}
}

.on-hold {
	--background: #eb445a;
	--color: white;
}

.no-course-content {
	border-style: dashed;
	margin: 12px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.no-course-content-container {

	display: flex;
	align-items: stretch;
	min-height: 100%;
	height: 100%;
	padding-top: 12px;
}

.completed-course {
	padding: 30px;
	margin: 0px;
}

.last-child-margin{
	>:last-child {
		margin-bottom: 16px;
	}
}

.loading-card {
	box-shadow: var(--box-shadow);
	color: var(--ion-text-color);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0;
	padding: 15px;
	height: 100%;

}

.loading-heading {
	align-items: center;
	display: flex;

	&__icon {
		margin-left: 2px;
		margin-right: 10px;
	}
}
</style>

<template>
	<ion-page>
		<ion-loading v-if="loading" show-backdrop />
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button v-if="store.state.showSettingsData?.type !== 'preferences'" @click="goBack()">
						<ion-icon color="light" :md="arrowBack" :ios="arrowBack"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title color="light">Preferences</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="hideSettingsModal()">
						<ion-icon color="light" :md="close" :ios="close"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="preferences">
			<form class="ion-padding">
				<heading
					:icon="calendar"
					:noTopMargin="true"
					:title="'Scheduler'"
				/>
				<ion-list>
					<ion-item
						ref="preferredPickupLocations"
						:class="[!validPreferredPickupLocations ? 'preferred-pickup-locations-item--invalid' : '']"
					>
						<ion-label>Preferred Pickup Locations</ion-label>
						<ion-select
							aria-label="Preferred Pickup Locations"
							interface="modal"
							:interfaceOptions="{
								cssClass: 'locations-multiselect-alert'
							}"
							:placeholder="preferredPickupLocationsPlaceholderText"
							:multiple="true"
							:value="settings.preferredPickupLocations"
							@ionChange="updatePreferredPickupLocations"
						>
							<ion-select-option
								class="location-item"
								:key="location.name + location.address"
								:value="location"
								v-for="location in pickupLocationsQueryResults"
							>
								{{location.name}}{{(location.address) ? ' - ' + location.address : ''}}
							</ion-select-option>
						</ion-select>
						<ion-note slot="error" v-if="!validPreferredPickupLocations">{{ preferredPickupLocationsPlaceholderText }}</ion-note>
					</ion-item>
				</ion-list>

				<p class="ion-padding ion-text-right">
					<ion-button color="success" @click="saveSettings()" :disabled="!validPreferredPickupLocations">Save Settings</ion-button>
				</p>
			</form>
		</ion-content>
	</ion-page>
</template>

<script>
import { api, UPDATE_USER_QUERY } from '@/core/api/api';
import { computed } from "vue";
import { defineComponent } from "vue";
import {
	arrowBack,
	alertCircle,
	calendar,
	checkmarkCircle,
	close,
	sunny
} from 'ionicons/icons';
import { toast } from '@/core/toast/Toast';
import { useQuery, useMutation } from '@vue/apollo-composable';
import {
	IonContent,
	IonHeader,
	IonIcon,
	IonTitle,
	IonToolbar,
	IonLoading
} from '@ionic/vue';
import { useStore } from 'vuex';
import envConfig from "@/core/config/env.config";
import {
	LOCATIONS_QUERY
} from '@/core/api/schedulerApi';
import Heading from '@/view/components/Heading.vue';

export default defineComponent({
	name: "settingsPreferences",
	components: {
		Heading,
		IonContent,
		IonHeader,
		IonIcon,
		IonTitle,
		IonToolbar,
		IonLoading
	},
	computed: {
		loading() {
			return this.pickupLocationsQuery.loading.value || this.mountedLoading;
		},
		minimumRequiredPickupLocations() {
			return this.store?.getters?.institutionSettings?.schedulerSettings?.preferredPickupLocations?.minimumRequired || 0;
		},
		preferredPickupLocationsPlaceholderText() {
			let placeholderText = 'Select at least ' + this.minimumRequiredPickupLocations + ' location';

			if (this.minimumRequiredPickupLocations === 0) {
				return 'Select preferred locations';
			}

			if (this.minimumRequiredPickupLocations > 1) {
				placeholderText += 's';
			}

			return placeholderText;
		},
		validPreferredPickupLocations() {
			// If preferred pickup locations are not enabled and or minimum is not set always be true.
			if (!this.store?.getters?.institutionSettings?.schedulerSettings?.preferredPickupLocations?.enabled || this.minimumRequiredPickupLocations === 0) { return true; }

			// If we meet the minimum required.
			if (this.settings.preferredPickupLocations.length >= this.minimumRequiredPickupLocations ) { return true; }

			// Default to false;
			return false;
		}
	},
	data() {
		return {
			preferredPickupLocations: [],
			user: {
				schedulerPreferences: {
					preferredPickupLocations: []
				}
			},
			settings: {
				preferredPickupLocations: []
			},
			mountedLoading: false
		}
	},
	methods: {
		async saveSettings() {
			this.updateUser({
				userID: this.user.userID,
				selectedUserFields: {
					preferredPickupLocations: this.getLocationIDs(this.settings.preferredPickupLocations)
				}
			});

			if(this.store.state.showSettingsData?.type === 'preferences') {
				this.hideSettingsModal();
			} else {
				this.settingsIonNav.pop();
			}
		},
		getLocationIDs(locationsArray) {
			if (!locationsArray) { return []; }

			return locationsArray.map(location => {
				return location.locationID;
			});
		},
		getLocationsFromIDs(locationIDsArray) {
			if (!this.pickupLocationsQueryResults) { return []; }

			let responseArray = locationIDsArray.map(locationID => {
				const location = this.pickupLocationsQueryResults.find(location => location.locationID === locationID);
				if (location) {
					return location;
				}

				// If a location no longer exists and it wasn't removed form preferred pickup locations set generic location.
				return {

				}
			});

			return responseArray;
		},
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		},
		updatePreferredPickupLocations({ detail }) {
			this.settings.preferredPickupLocations = detail.value;
			this.validate();
		},
		validate() {
			this.$refs.preferredPickupLocations.$el.classList.remove('ion-valid');
			this.$refs.preferredPickupLocations.$el.classList.remove('ion-invalid');


			if (!this.validPreferredPickupLocations) {
				this.$refs.preferredPickupLocations.$el.classList.add('ion-invalid');
				return false;
			} else {
				this.$refs.preferredPickupLocations.$el.classList.add('ion-valid');
				return true;
			}
		},
	},
	async mounted() {
		try {
			this.mountedLoading = true;
			let user = null;

			// Get the user settings.
			if(this.store.state.showSettingsData?.type === 'preferences') {
				user = await api.getUserDetails(this.store.state.showSettingsData.user);
			} else {
				user = await api.getUserDetails(this.$attrs.userID);
			}

			// Get the full locations for preferred pickup locations if the user has them.
			if (user?.schedulerPreferences?.preferredPickupLocations) {

				this.settings.preferredPickupLocations = this.getLocationsFromIDs(user.schedulerPreferences.preferredPickupLocations);
			}

			// Set the user data
			this.user = user;

			// TODO: Update to not be specific to just preferredPickupLocations.
			this.setLogEvent(this.user.schedulerPreferences?.preferredPickupLocations ? 'Update Preferences' : 'Added Preferences');
			this.mountedLoading = false;
		} catch (e) {
			this.mountedLoading = false;
			console.error(e);
		}
	},
	props: [
		"modalNav"
	],
	setup(props) {
		const store = useStore();

		const settingsIonNav = document.querySelector('ion-nav');
		let addingOrUpdatingPreferredPickupLocations = '';

		const pickupLocationsQuery = useQuery(
			LOCATIONS_QUERY, () => ({
				institution: store.getters.institutionSettings.slug,
				locationType: [
					'PICKUP'
				]
			}),
			{
				fetchPolicy: 'cache-and-network'
			}
		);

		pickupLocationsQuery.onError(error => {
			console.error(error);

			toast.error({
				message: 'Pickup locations cannot be retrieved at this time',
				duration: 7500,
				position: 'top'
			});
		});

		const pickupLocationsQueryResults = computed(() => pickupLocationsQuery?.result?.value?.locations ?? []);

		// TODO: Review
		const logData = (status) => {
			const logData = {
				institution: store.getters.institutionSettings.slug,
				event: addingOrUpdatingPreferredPickupLocations,
				affectedUserID: store.getters.user.userID,
				additionalData:{
					email: store.getters.user.email,
					activeUser: store.getters.selectedUser,
					currentAppVersions: store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status
				}
			};
			api.logMobileActivity(logData);
		}

		const setLogEvent = (event) => {
			addingOrUpdatingPreferredPickupLocations = event;
		}

		const {
			mutate: updateUser,
			onDone: updateUserOnDone,
			onError: updateUserOnError
		} = useMutation( UPDATE_USER_QUERY );

		updateUserOnDone(async () => {
			logData('Success');
			toast.success({
				message: 'Preferences successfully updated.',
				duration: 3000
			});
			await store.dispatch('getStudents', store.state.auth.user.userID);
		});

		updateUserOnError(error => {
			logData('Failure');
			toast.error({
				message: 'Unable to update preferences at this time. Please try again later.',
				duration: 3000
			});
		});

		const goBack = () => {
			const nav = props.modalNav.value;
			nav.pop();
		}

		return {
			alertCircle,
			arrowBack,
			calendar,
			checkmarkCircle,
			close,
			goBack,
			pickupLocationsQuery,
			pickupLocationsQueryResults,
			store,
			sunny,
			updateUser,
			settingsIonNav,
			setLogEvent
		};
	}
});
</script>

<style lang="scss">
#app .locations-multiselect-alert {
	--max-width: 540px;
	--min-width: 365px;
	--width: auto;

	.alert-tappable.sc-ion-alert-md {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		height: 70px;

		.alert-checkbox-label {
			display: flex;
			flex-direction: column;
			overflow: visible;
			text-wrap: wrap;
		}
	}
}
</style>

<style scoped lang="scss">
ion-content,
ion-item,
ion-item ion-note,
ion-header {
	ion-toolbar {
		background-color: var(--ion-color-primary);
		border: 0;
	}
}

ion-select::part(text) {
	color: var(--ion-color-primary);
}

ion-select::part(icon) {
	color: var(--ion-color-primary);
	opacity: 1;
}

.preferred-pickup-locations-item--invalid {
	--background: var(--ion-color-danger-lighten-9);
	--border-color: var(--ion-color-danger);
	--border-top-width: 1px;
	--border-bottom-width: 1px;
	--color: var(--ion-color-primary);
}

.form-item {
	min-height: 70px;
}
</style>
<template>
	<ion-modal id="ion-settings-modal" :is-open="store.state.showSettingsModal" @willDismiss="hideSettingsModal()">
		<ion-nav
			:root="startingView"
			id="settings-ion-nav">
		</ion-nav>
	</ion-modal>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { IonModal, IonNav } from "@ionic/vue";
import { useStore } from 'vuex';
import settingsNav from './SettingsNav.vue';
import settingsUserDetails from './SettingsUserDetails.vue';
import settingsBilling from "./SettingsBilling.vue";
import settingsAppointmentHistory from "./SettingsAppointmentHistory.vue";
import settingsPreferences from './SettingsPreferences.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
	components: {
		IonModal,
		IonNav
	},
	setup() {
		const store = useStore();
		const route = useRoute();

		return {
			settingsAppointmentHistory,
			settingsUserDetails,
			settingsBilling,
			settingsNav,
			settingsPreferences,
			store,
		}
	},
	computed: {
		startingView() {
			if (this.store.state.showSettingsData?.type === 'userDetails') return settingsUserDetails;
			if (this.store.state.showSettingsData?.type === 'billing') return settingsBilling;
			if (this.store.state.showSettingsData?.type === 'appointmentHistory') return settingsAppointmentHistory;
			if (this.store.state.showSettingsData?.type === 'preferences') return settingsPreferences;
			return settingsNav;
		},
	},
	methods: {
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		},
	},
});
</script>

<style lang="scss" scoped>
ion-modal::part(content) {
	opacity: 1;
}
</style>
<template>
	<ion-modal
		v-if="!loading"
		canDismiss="false"
		:class="['reservation-confirmation-modal', modalHeightClass]"
		ref="modal"
		:is-open="reserveModalOpen"
	>
		<ion-content scroll-y class="reservation-confirmation-content">
			<p class="reservation-confirmation-content__header">
				Confirm Reservation
			</p>

			<div
				v-for="(session) in orderReservations"
				:key="session.sessionStart"
				class="reservation-booking-group"
			>
				<div class="reservation-booking-group__text">
					<p>
						<b>{{ formatTime(session.sessionStart) }} - {{ formatTime(session.sessionEnd) }}</b>
					</p>
					<p v-if="session.customPickupLocation?.name || (selectedTimeSlot.location?.name && selectedTimeSlot.location?.locationID !== 'none')">
						{{ session.customPickupLocation?.name || selectedTimeSlot.location?.name }}
					</p>
				</div>
				<div
					class="reservation-booking-group__icon"
					v-if="session.sessionType === 'observe'"
				>
					<ion-icon
						color="dark"
						src="/assets/icon-eye.svg"
					/>
				</div>
				<div
					v-if="session.sessionType === 'drive'"
					class="reservation-booking-group__icon"
				>
					<ion-icon
						color="dark"
						src="/assets/icon-gauge-low.svg"
					/>
				</div>
			</div>
			<div class="reservation-confirmation-content__buttons">
				<ion-button
					@click="closeModal"
					expand="block"
					class="cancel-button"
				>
					CANCEL
				</ion-button>
				<ion-button
					@click="confirmReservation"
					expand="block"
					class="confirm-button"
				>
					CONFIRM
				</ion-button>
			</div>
		</ion-content>
	</ion-modal>
</template>

<script lang="ts">
import { IonModal } from '@ionic/vue';
import { defineComponent, PropType, ref } from 'vue';
import { useStore } from "vuex";
import { toast } from '@/core/toast/Toast';
import omitDeep from 'omit-deep-lodash';
import { useMutation } from '@vue/apollo-composable';
import {
	CREATE_APPOINTMENT_MUTATION,
} from '@/core/api/schedulerApi';
import { api } from "@/core/api/api";
import envConfig from '@/core/config/env.config';
import { getSessionsTimeSlotLoggingDetails, formatTime } from '@/core/util/helpers';
import { TimeSlotVM } from '@/core/store/store';

export default defineComponent({
	components: { IonModal },
	props: {
		reservationsState: {
			type: Object,
			default: () => {},
		},
		selectedTimeSlot: {
			type: Object as PropType<TimeSlotVM>,
			default: () => {},
		},
		reserveModalOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	setup(props, context) {
		const store = useStore();
		const createAppointmentVariables = ref({
			sessions: [],
			date: null,
			institution: null,
			timeSlotID: null,
			userID: null,
		});

		const logData = (type, status, details) => {
			api.logMobileActivity({
				institution: store.getters.institutionSettings.slug,
				event: type,
				affectedUserID: store.getters.user.userID,
				additionalData:{
					email: store.getters.user.email,
					activeUser: store.getters.selectedUser,
					currentAppVersions: store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status,
					details
				}
			});
		}

		const createAppointment = useMutation(
			CREATE_APPOINTMENT_MUTATION,
			() => {
				const loggingDetails = getSessionsTimeSlotLoggingDetails(createAppointmentVariables.value.sessions, store.getters.institutionSettings);

				logData('Scheduled Appointment', 'Success', loggingDetails);

				return {
					variables: omitDeep(createAppointmentVariables.value, 'customPickupLocation')
				}
			});

		createAppointment.onDone(result => {
			context.emit('refetchTimeSlots');
		});

		createAppointment.onError(error => {
			const loggingDetails = getSessionsTimeSlotLoggingDetails(createAppointmentVariables.value.sessions, store.getters.institutionSettings);

			logData('Scheduled Appointment', 'Failure', loggingDetails);

			toast.error({
				message: error.message,
				duration: 7500,
				position: 'top'
			});
			context.emit('refetchTimeSlots');
		});

		return {
			store,
			formatTime,
			logData,
			createAppointment,
			createAppointmentVariables,
		}
	},
	methods: {
		omitDeep,
		closeModal() {
			this.$emit('closeConfirmModal');
		},
		async confirmReservation() {
			try {
				this.loading = true;
				this.$emit('setLoading', this.loading);

				this.createAppointmentVariables = this.omitDeep({
					sessions: this.orderReservations,
					date: this.selectedTimeSlot.instance.date,
					institution: this.store.getters.user.institutions[0],
					timeSlotID: this.selectedTimeSlot.timeSlotID,
					userID: this.store.getters.selectedUser || this.store.getters.user.userID,
				}, '__typename');

				await this.createAppointment.mutate();

				this.loading = false;
				this.$emit('setLoading', this.loading);
				this.closeModal();
			} catch (e) {
				this.loading = false;
				this.$emit('setLoading', this.loading);
				console.error(e);
			} finally {
				this.store.dispatch('getStudents', this.store.getters.user.userID);
			}
		},

	},
	computed: {
		orderReservations() {// TODO
			return Object.entries(this.reservationsState).sort((a, b) => {
				return a[0] > b[0] ? 1 : -1;
			}).map(reservation => {
				return {
					sessionType: reservation[1].sessionType,
					sessionEnd: reservation[1].sessionEnd,
					sessionStart: reservation[0],
					customPickupLocation: this.selectedTimeSlot.instance.sessions.find(session => session.sessionStart === reservation[0])?.customPickupLocation || null,
				};
			});
		},
		modalHeightClass() {
			switch(Object.keys(this.reservationsState).length) {
				case 1:
					return 'twenty-percent';
				case 2:
					return 'thirty-percent';
				case 3:
					return 'forty-percent';
				default:
					return 'fifty-percent';
			}
		}
	}
});
</script>

<style lang="scss" scoped>
.reservation-confirmation-modal {
	--max-width: 80%;
	--border-radius: 6px;

	ion-content {
		height: 100%;
	}
}

ion-content::part(scroll) {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px;
}

.reservation-confirmation-content {
	--background: var(--ion-color-primary-tint);

	&__header {
		color: var(--ion-color-primary-contrast, #FFF);
		text-align: center;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 147%;
		letter-spacing: 0.78px;
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		width: 100%;


		ion-button {
			width: 100%;
			font-size: 13px;
			font-weight: 700;
			line-height: 147%;
			letter-spacing: 0.78px;
			--border-radius: 6px;
		}

		.cancel-button {
			--background: var(--ion-color-primary-lighten-3, #6B7985);
		}
		.confirm-button {
			--background: var(--ion-color-success);
		}
	}
}

.twenty-percent {
	--height: 20%;
}
.thirty-percent {
	--height: 30%;
}
.forty-percent {
	--height: 40%;
}
.fifty-percent {
	--height: 50%;
}

.reservation-booking-group {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #FFF;


	&__icon {
		border-radius: 150px;
		background: #FFF;
		width: 50px;
		height: 50px;

		ion-icon {
			height: 40px;
			width: 40px;
			padding: 5px;
		}
	}

	&__text {

		display: flex;
		font-size: 12px;
		font-weight: 700;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
	}


}
</style>